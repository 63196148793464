import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Hizmetlerimiz | Flex & Burn Gym'in Hizmetleri
			</title>
			<meta name={"description"} content={"Her Tekrar, Her Set, Her Adım - Size Özel"} />
			<meta property={"og:title"} content={"Hizmetlerimiz | Flex & Burn Gym'in Hizmetleri"} />
			<meta property={"og:description"} content={"Her Tekrar, Her Set, Her Adım - Size Özel"} />
			<meta property={"og:image"} content={"https://oldicombotru.com/img/523525.jpg"} />
			<link rel={"shortcut icon"} href={"https://oldicombotru.com/img/350flexedbiceps2_100913.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://oldicombotru.com/img/350flexedbiceps2_100913.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://oldicombotru.com/img/350flexedbiceps2_100913.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://oldicombotru.com/img/350flexedbiceps2_100913.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://oldicombotru.com/img/350flexedbiceps2_100913.png"} />
			<meta name={"msapplication-TileImage"} content={"https://oldicombotru.com/img/350flexedbiceps2_100913.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" color="--light" padding="80px 0" sm-padding="40px 0">
			<Text
				as="h1"
				font="--headline1"
				color="--primary"
				md-font="--headline2"
				margin="10px 0 0 0"
			>
				Hizmet Spot Işığı
			</Text>
			<Text
				as="p"
				font="--lead"
				color="--dark"
				margin="40px 0 20px 0"
				text-align="left"
			>
				Her fitness seviyesine ve isteğe uygun olarak tasarlanmış bir dizi hizmet sunduğumuz Flex & Burn Gym'deki çeşitli tekliflere dalın. İşte sunduklarımızın bir özeti:
			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="center"
					md-text-align="center"
					sm-text-align="left"
				>
					Hizmetlerimiz
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Kişisel Eğitim
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						• Bire Bir Seanslar: Özel egzersiz planları ile fitness hedeflerinize ulaşmanıza yardımcı olmak için kişiselleştirilmiş ilgi.
						<br />
						<br />
						• İkili Antrenman: Motive eden ve meydan okuyan ortak bir antrenman deneyimi için bir arkadaşınızla veya partnerinizle eşleşin.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Grup Dersleri
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						• Yüksek Enerjili Kardiyo: Yüksek yoğunluklu interval antrenmanlardan dans temelli egzersizlere kadar çeşitli kardiyo sınıflarımızla ter atın.
						<br />
						<br />
						• Güç ve Kondisyon: Direnç antrenmanı ve fonksiyonel fitness odaklı derslerle kas ve dayanıklılık geliştirin.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Uzmanlık Programları
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						• Gençlik Fitness: Gençlere yönelik eğlenceli, yaşa uygun fitness aktiviteleriyle sağlıklı alışkanlıkları erkenden teşvik etmek.
						<br />
						<br />
						• Kıdemli Güç: Yaşlı üyelerimiz için hareketliliği, gücü ve dengeyi korumak ve geliştirmek için özel olarak hazırlanmış programlar.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Sağlık Hizmetleri
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						• Beslenme Danışmanlığı: Fitness rutininizi tamamlayacak ve genel sağlığınızı destekleyecek beslenme alışkanlıkları konusunda uzman tavsiyesi.
						<br />
						<br />
						• Zihin ve Beden Dersleri: Esnekliği, çekirdek gücünü ve zihinsel refahı artırmak için Yoga ve Pilates seansları.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Tesisler
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						• Açık Spor Salonu Erişimi: Tüm üyeler için serbest ağırlıklar, makineler ve kardiyo ekipmanlarının bulunduğu tam donanımlı bir spor salonu katı.
						<br />
						<br />
						• Temiz ve Konforlu Soyunma Odaları: Antrenmanınızdan sonra temiz bir ortamda yenilenmenizi ve rahatlamanızı sağlar.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Topluluk ve Destek
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						• Etkinlikler & Atölye Çalışmaları: Sağlık, eğitim ve beslenme konularına odaklanan düzenli olarak planlanan toplum etkinlikleri.
						<br />
						<br />
						• Sürekli Destek: Ekibimiz soruları yanıtlamak, ipuçları vermek ve sizi motive etmek için her zaman hazırdır.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Değişime hazır mısınız?
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Hizmetlerimiz hakkında daha fazla bilgi edinmek için bize ulaşın veya bir tur için uğrayın. Fitness yolculuğunuzu her adımda desteklemek için buradayız.
				</Text>
				<Link
					href="/contacts"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					İletişime Geçin
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});